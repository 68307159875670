import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Table, notification, Popconfirm } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'
import Highlighter from 'react-highlight-words';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'
import SubmitButton from '../../Components/SubmitButton'
import { serverUrl } from '../../Config/api'

const title = "Error"

class RegionScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            disableUpload: false,
            StartDateValue: '',
            EndDateValue: '',
            disable: false,
            tableData: [],
            allData: [],
            focusData: [],
            visible: false
        }
    }

    async componentDidMount() {
        const { user } = this.props
        const { tableData } = this.state

        if (!user || user.role !== 'admin') {
            this.props.history.replace('/login')
        }
        await axios.get(`${serverUrl}/admin/get/get-region/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        name: data[i].name,
                        noBu: data[i].bu,
                        noCu: data[i].cu,
                        beginZip: data[i].beginZip.join(''),
                        endZip: data[i].endZip.join(''),
                        action: data[i],
                        delete: data[i]._id,
                        index: i
                    })
                }
                this.setState({ tableData, allData: data })
            })
            .catch((e) => console.log(e))
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });


    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        const { name, endZip } = v
        var zip = `${String(parseInt(endZip[0]) + 1000)}${endZip[1]}`
        setFieldsValue({
            name,
            beginZip: `${String(parseInt(endZip[0]) + 1)}${endZip[1]}`,
            endZip: zip
        })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    handleSubmit = e => {
        const { allData } = this.state
        const { user, form } = this.props
        const { resetFields, validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                values.name = `${values.name.charAt(0)}${values.name.slice(1)}`
                var beginZip = values.beginZip.split(/([0-9]+)/).filter(Boolean)
                var endZip = values.endZip.split(/([0-9]+)/).filter(Boolean)
                if (beginZip[0] > endZip[0]) {
                    return this.notificationWarning('OOPS', 'Begin Zip must be less than end Zip!')
                }
                else if (beginZip[0] === endZip[0] && beginZip[1].trim().toUpperCase() > endZip[1].trim().toUpperCase()) {
                    return this.notificationWarning('OOPS', 'Begin Zip must be less than end Zip!!')
                }
                var filterBegin
                var filterEnd
                for (var v of allData) {
                    filterBegin = v.beginZip
                    filterEnd = v.endZip
                    if (beginZip[0] <= filterBegin[0] && endZip[0] >= filterBegin[0] && beginZip[1].trim().toUpperCase() <= filterBegin[1] && endZip[1].trim().toUpperCase() >= filterBegin[1]) {
                        return this.notificationWarning('OOPS', 'Zip range already created for this region!')
                    }
                    else if (beginZip[0] <= filterEnd[0] && endZip[0] >= filterEnd[0] && beginZip[1].trim().toUpperCase() <= filterEnd[1] && endZip[1].trim().toUpperCase() >= filterEnd[1]) {
                        return this.notificationWarning('OOPS', 'Zip range already created for this region!')
                    }
                    else if (beginZip[0] >= filterBegin[0] && endZip[0] <= filterEnd[0] && beginZip[1].trim().toUpperCase() >= filterBegin[1] && endZip[1].trim().toUpperCase() <= filterEnd[1]) {
                        return this.notificationWarning('OOPS', 'Zip range already created for this region!')
                    }
                    else if (beginZip[0] > filterBegin[0] && beginZip[0] < filterEnd[0] || endZip[0] > filterBegin[0] && endZip[0] < filterEnd[0]) {
                        return this.notificationWarning('OOPS', 'Zip range already created for this region!')
                    }
                }
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                values.beginZip = beginZip
                values.endZip = endZip
                values.name = `${values.name.charAt(0)}${values.name.slice(1)}`
                axios.post(`${serverUrl}/admin/post/add-region`, values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', 'Successfully Added!', 'check')
                            this.setState({ disable: false, loading: false })
                            resetFields()
                            setTimeout(() => {
                                window.location.reload()
                            }, 500)
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    notificationWarning = (title, desc) => {
        notification.warning({ message: title, description: desc })
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    async deleteData(v) {
        let { tableData, allData } = this.state
        await axios.post(`${serverUrl}/admin/post/region-delete`, { id: v })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData = tableData.filter(i => i.key !== v)
                    allData = allData.filter(i => i._id !== v)
                    this.setState({ tableData, allData })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    validateBeginZip = (rule, value, callback) => {
        const { form } = this.props;
        var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (!rege.test(form.getFieldValue('beginZip'))) {
            callback('Please Input Valid Begin ZipCode!');
        } else {
            callback();
        }
    };

    validateEndZip = (rule, value, callback) => {
        const { form } = this.props;
        var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (!rege.test(form.getFieldValue('endZip'))) {
            callback('Please Input Valid End ZipCode!');
        } else {
            callback();
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        let { sortedInfo, filteredInfo, loading, disable, tableData, focusData, visible } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Begin Zipcode',
                dataIndex: 'beginZip',
                sorter: (a, b) => a.beginZip.length - b.beginZip.length,
                sortOrder: sortedInfo.columnKey === 'beginZip' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('beginZip'),
            },
            {
                title: 'End Zipcode',
                dataIndex: 'endZip',
                sorter: (a, b) => a.endZip.length - b.endZip.length,
                sortOrder: sortedInfo.columnKey === 'endZip' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('endZip'),
            },
            {
                title: "Number of bu's",
                dataIndex: 'noBu',
                sorter: (a, b) => a.noBu.length - b.noBu.length,
                sortOrder: sortedInfo.columnKey === 'noBu' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('noBu'),
            },
            {
                title: "Number of cu's",
                dataIndex: 'noCu',
                sorter: (a, b) => a.noCu.length - b.noCu.length,
                sortOrder: sortedInfo.columnKey === 'noCu' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('noCu'),
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.createCopyData(v)}>Create</Button>
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={() => this.deleteData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a className="nav-link dropdown-toggle nav-link-child" href="#" id="navbarDropdownChild" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Delivery
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdownChild">
                                                <Link className="dropdown-item" to="/timeslot">TimeSlot</Link>
                                                <Link className="dropdown-item" to="/costs">Costs</Link>
                                            </div>
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li><li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>


                <div className="main-content-bg2">
                    <section>

                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Delivery Region</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={tableData}
                                    onChange={this.handleChange}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1200, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    onSubmit={this.handleSubmit}
                                    className="login-form"
                                    hideRequiredMark={true}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >PANTRY DELIVERY REGION</h1>
                                    <Form.Item
                                        label="Region Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please Input Region Name!' }],
                                        })(
                                            <Input placeholder="Region Name" minLength={3} />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Begin Zipcode"
                                    >
                                        {getFieldDecorator('beginZip', {
                                            rules: [
                                                {
                                                    validator: this.validateBeginZip,
                                                }
                                            ],
                                        })(
                                            <Input placeholder="Begin Zipcode" minLength={3} />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="End Zipcode"
                                    >
                                        {getFieldDecorator('endZip', {
                                            rules: [
                                                {
                                                    validator: this.validateEndZip,
                                                }
                                            ],
                                        })(
                                            <Input placeholder="End Zipcode" minLength={3} />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <SubmitButton loading={loading} disable={disable} />
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                        <Footer {...this.props} />
                    </section>
                </div>
            </div>
        )
    }
}

const RegionScreenForm = Form.create({ name: 'normal_login' })(RegionScreen);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(RegionScreenForm)