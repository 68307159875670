import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, Input, Button, Select, Table, notification, Popconfirm, TreeSelect, Switch } from 'antd';
import axios from 'axios'
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import QuestionModal from './QuestionModal'
import ShowQuestion from './ShowQuestion'
import { serverUrl } from '../../Config/api'

const { Option } = Select

var title = "Error"
var expandData = {}

const saleType1 = [
    {
        key: 'sports',
        value: 'Do you like Sports?'
    },
    {
        key: 'football',
        value: 'Do you like Football?'
    },
    {
        key: 'react',
        value: 'Do you like React.js'
    }
]
const saleType = ["A", "B", "C", "D", "E"]

const data = [];
for (let i = 1; i < 46; i++) {
    data.push({
        key: i,
        name: `Q&A_00${i}`,
        topic: i,
        ans1: 'h',
        address: i > 4 ? `A_0${Math.round(i / 4) > 4 ? Math.round(Math.round(i / 4) / 4) : Math.round(i / 4)}` : `A_0${i}`
    });
}

class FocusGroup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            disable: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            visible: false,
            selectedQuestionAns: [],
            question: '',
            tableData: [],
            allData: [],
            key: '',
            createData: {},
            showModal: false,
            currentQuestion: {},
            questionsData: [],
            answers: {},
            groceryData: [],
            topicsData: [],
            selectedData: [],
            allProducts: [],
            buSwitch: false,
            cuSwitch: false,
            productNameList: [],
            currentTopic: []
        }
    }

    componentDidMount() {
        const { user } = this.props
        let { tableData, questionsData, topicsData } = this.state
        if (!user || user.role !== 'admin') {
            this.props.history.replace('/login')
        }
        axios.get(`${serverUrl}/admin/get/user-question/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i of data) {
                    questionsData.push(i)
                }
                // questionsData = questionsData.filter((v, i, a) => a.indexOf(v) === i);
                this.setState({ questionsData })
            })
            .catch((e) => console.log(e))

        axios.get(`${serverUrl}/admin/get/get-product/${user._id}`)
            .then((result) => {
                const { data, success } = result.data
                if (success) {
                    this.setState({ isData: topicsData.length, allProducts: data })
                }
            })

        axios.get(`${serverUrl}/admin/get/get-focus/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        name: data[i].name,
                        consumers: 1,
                        index: i,
                        bu: data[i].buSwitch ? 'True' : 'False',
                        cu: data[i].cuSwitch ? 'True' : 'False',
                        action: data[i],
                        delete: data[i]._id
                    })
                }
                this.setState({ tableData, allData: data })
            })
            .catch((e) => console.log(e))

        axios.get(`${serverUrl}/admin/get/get-topic-table/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    this.setState({ topicsData: data.data })
                }
            })
            .catch((e) => console.log(e))
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    showModalData(v) {
        this.setState({ currentQuestion: v, showModal: true })
    }

    expandedRowRender = () => {
        const columns = [
            {
                title: 'Q & A Names',
                ellipsis: true,
                children: [
                    {
                        title: expandData.questions[0] ? expandData.questions[0].key : 'Empty',
                        dataIndex: 'q1',
                        ellipsis: true,
                        render: (v) => <p onClick={() => this.showModalData(expandData.questions[0])}>{v}</p>
                    },
                    {
                        title: expandData.questions[1] ? expandData.questions[1].key : 'Empty',
                        dataIndex: 'q2',
                        ellipsis: true,
                    },
                    {
                        title: expandData.questions[2] ? expandData.questions[2].key : 'Empty',
                        dataIndex: 'q3',
                        ellipsis: true,
                    }
                ]
            },
            {
                title: 'Products Name',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'p1',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'p2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'p3',
                        ellipsis: true,
                    }
                ]
            },
            // {
            //     title: 'Brands',
            //     ellipsis: true,
            //     children: [
            //         {
            //             title: 'Name 1',
            //             dataIndex: 'b1',
            //             ellipsis: true,
            //         },
            //         {
            //             title: 'Name 2',
            //             dataIndex: 'b2',
            //             ellipsis: true,
            //         },
            //         {
            //             title: 'Name 3',
            //             dataIndex: 'b3',
            //             ellipsis: true,
            //         }
            //     ]
            // }
        ]
        const data = [{
            key: expandData._id,
            q1: expandData.questions[0] ? expandData.questions[0].answers.length : '',
            q2: expandData.questions[1] ? expandData.questions[1].answers.length : '',
            q3: expandData.questions[2] ? expandData.questions[2].answers.length : '',
            p1: expandData.productNameList[0] ? expandData.productNameList[0] : '',
            p2: expandData.productNameList[1] ? expandData.productNameList[1] : '',
            p3: expandData.productNameList[2] ? expandData.productNameList[2] : ''
        }]
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    handleOk = (selected) => {
        const { selectedQuestionAns, key } = this.state
        selectedQuestionAns.push({ ...selected, key: key })
        this.setState({ selectedQuestionAns, visible: false })
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    handleCancel = () => {
        const { key } = this.state
        const { setFieldsValue, getFieldsValue } = this.props.form
        var { questions } = getFieldsValue()
        questions = questions.filter(v => v !== key)
        setFieldsValue({
            'questions': questions
        })
        this.setState({ visible: false });
    };

    addQuestion = (e) => {
        let { questionsData, answers } = this.state
        var val = this.checkLimit('questions')
        if (val) {
            var question = questionsData.filter(v => v.name === e)
            for (var i = 1; i <= 5; i++) {
                if (question[0][`ans${i}`]) {
                    answers[`ans${i}`] = question[0][`ans${i}`]
                }
            }
            this.setState({ visible: true, key: e, question: question[0], answers })
        }
    }

    removeQuestion = (e) => {
        var { selectedQuestionAns } = this.state
        selectedQuestionAns = selectedQuestionAns.filter(v => v.key !== e)
        this.setState({ selectedQuestionAns })
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleSubmit = e => {
        const { selectedQuestionAns, selectedData, productNameList } = this.state
        const { user, form } = this.props
        const { resetFields, validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                if (selectedQuestionAns.length) {
                    values.questions = selectedQuestionAns
                }
                if (!values.buSwitch) {
                    values.productNameData = selectedData
                    values.productNameList = productNameList
                }
                axios.post(`${serverUrl}/admin/post/add-focus`, values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            resetFields()
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    handleExpand = (row) => {
        const { allData } = this.state
        expandData = allData[row.index]
    }

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        let { buSwitch, cuSwitch, topicId, name, productName } = v
        this.updateOrderOfProducts(topicId)
        this.setState({ buSwitch, cuSwitch })
        setFieldsValue({
            name,
            productName,
            buSwitch,
            cuSwitch,
            topicId
        })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    async deleteData(v) {
        let { tableData } = this.state
        await axios.get(`${serverUrl}/del/admin-focus/${v}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData = tableData.filter(i => i.key !== v)
                    this.setState({ tableData })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    updateOrderOfProducts = e => {
        const { topicsData, allProducts } = this.state
        let productNameList = []
        var groc = []
        var groceryData = []
        var topic = topicsData.filter(v => v._id === e)[0]

        for (var j in allProducts) {
            if (topic.name === allProducts[j].topic.name) {
                groc.push(allProducts[j])
            }
        }

        for (var i in groc) {
            var obj = {
                title: groc[i].groceryName,
                value: i,
                key: i,
                children: []
            }
            var { excelFileObj } = groc[i]
            var count = 0
            for (var j in excelFileObj) {
                if (j !== "null") {
                    obj.children.push({
                        title: j,
                        value: `${i}-${count}`,
                        key: `${i}-${count}`,
                        children: []
                    })
                    var childCount = 0
                    for (var k in excelFileObj[j]) {
                        if (k !== "null") {
                            obj.children[count].children.push({
                                title: k,
                                value: `${i}-${count}-${childCount}`,
                                key: `${i}-${count}-${childCount}`,
                                children: []
                            })
                            for (var l in excelFileObj[j][k]) {
                                productNameList.push(excelFileObj[j][k][l]['product_title'])
                                obj.children[count].children[childCount].children.push({
                                    title: excelFileObj[j][k][l]['product_title'],
                                    value: `${i}-${count}-${childCount}-${l}`,
                                    key: `${i}-${count}-${childCount}-${l}`,
                                    data: excelFileObj[j][k][l]
                                })
                            }
                            childCount++
                        }
                    }
                    count++
                }
            }
            groceryData.push(obj)
        }
        this.setState({ groceryData, groceryArr: groc, topic, productNameList })
    }

    updateChild = e => {
        const { groceryData } = this.state
        let myData = []
        for (var v of e) {
            for (var i of groceryData) {
                if (i.value === v.charAt(0)) {
                    var data = myData.filter(d => d.value === v.charAt(0))
                    if (!data.length) {
                        myData.push({
                            title: i.title,
                            value: i.value,
                            key: i.key,
                            children: []
                        })
                    }
                    const { children } = i
                    for (var j of children) {
                        if (j.value === v.slice(0, 3)) {
                            var data1 = myData.map((d, index) => {
                                if (d.value === v.charAt(0)) {
                                    return index
                                }
                            })
                            var key = data1.filter(d => d !== undefined)[0]
                            var productGroup = myData[key].children.filter(d => d.value === j.value)
                            if (!productGroup.length) {
                                myData[key].children.push({
                                    title: j.title,
                                    value: j.value,
                                    key: j.key,
                                    children: []
                                })
                            }
                            var groupChild = j.children
                            for (var k of groupChild) {
                                if (k.value === v.slice(0, 5)) {
                                    var data2 = myData[key].children.map((d, ind) => {
                                        if (d.value === v.slice(0, 3)) {
                                            return ind
                                        }
                                    })
                                    var key1 = data2.filter(d => d !== undefined)[0]
                                    var productType = myData[key].children[key1].children.filter(d => d.value === k.value)
                                    if (!productType.length) {
                                        myData[key].children[key1].children.push({
                                            title: k.title,
                                            value: k.value,
                                            key: k.key,
                                            children: []
                                        })
                                    }
                                    var titleChild = k.children
                                    for (var l of titleChild) {
                                        if (l.value === v) {
                                            var data3 = myData[key].children[key1].children.map((d, ind) => {
                                                if (d.value === v.slice(0, 5)) {
                                                    return ind
                                                }
                                            })

                                            var key2 = data3.filter(d => d !== undefined)[0]
                                            var productTitle = myData[key].children[key1].children[key2].children.filter(d => d.value === k.value)
                                            if (!productTitle.length) {
                                                myData[key].children[key1].children[key2].children.push({
                                                    title: l.title,
                                                    value: l.value,
                                                    key: l.key,
                                                    data: l.data
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
        this.setState({ selectedData: myData })
    }

    updateTopic = () => {
        const { buSwitch, cuSwitch, topicsData } = this.state
        let currentTopic = []
        for (var i of topicsData) {
            if (buSwitch && i.buSwitch || cuSwitch && i.cuSwitch) {
                currentTopic.push(i)
            }
        }
        this.setState({ currentTopic })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { sortedInfo, filteredInfo, loading, disable, visible, tableData, showModal, currentQuestion, questionsData, groceryData, topicsData, buSwitch, cuSwitch, currentTopic } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Focus Group Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true
            },
            {
                title: 'BU',
                dataIndex: 'bu',
                ellipsis: true
            },
            {
                title: 'CU',
                dataIndex: 'cu',
                ellipsis: true
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.createCopyData(v)}>Create</Button>
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={() => this.deleteData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a className="nav-link dropdown-toggle nav-link-child" href="#" id="navbarDropdownChild" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Delivery
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdownChild">
                                                <Link className="dropdown-item" to="/timeslot">TimeSlot</Link>
                                                <Link className="dropdown-item" to="/region">Region</Link>
                                                <Link className="dropdown-item" to="/costs">Costs</Link>
                                            </div>
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li><li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg2">
                    <section>
                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Focus Group</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={tableData}
                                    onChange={this.handleChange}
                                    onRow={(row, rowIndex) => this.handleExpand(row)}
                                    expandedRowRender={this.expandedRowRender}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1000, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >Focusgroup Settings</h1>
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={3}
                                                placeholder="Name"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Q & A name"
                                    >
                                        {getFieldDecorator('questions', {
                                            rules: [{ required: false, message: 'Please Select Question!' }],
                                        })(
                                            <Select
                                                showSearch
                                                mode="tags"
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Question"
                                                optionFilterProp="children"
                                                showArrow
                                                onSelect={this.addQuestion}
                                                onDeselect={this.removeQuestion}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    questionsData.map((v, i) => {
                                                        return <Option value={v.name} key={i}>{v.name}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="BU"
                                    >
                                        {getFieldDecorator('buSwitch', {
                                            initialValue: false
                                        })(
                                            <Switch checked={buSwitch} onChange={e => this.setState({ buSwitch: e }, this.updateTopic)} />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="CU"
                                    >
                                        {getFieldDecorator('cuSwitch', {
                                            initialValue: false
                                        })(
                                            <Switch checked={cuSwitch} onChange={e => this.setState({ cuSwitch: e }, this.updateTopic)} />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Topic"
                                    >
                                        {getFieldDecorator('topicId', {
                                            rules: [{ required: false, message: 'Please Select Topic!' }],
                                        })(
                                            <Select
                                                showSearch
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Topic"
                                                optionFilterProp="children"
                                                onChange={this.updateOrderOfProducts}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    currentTopic.map((v, i) => {
                                                        return <Option value={v._id} key={i}>{v.name}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    {!buSwitch ? <Form.Item
                                        label="Product Name"
                                    >
                                        {getFieldDecorator('productName', {
                                            rules: [{ required: false, message: 'Please input Product Name!' }],
                                        })(
                                            <TreeSelect
                                                treeData={groceryData.length ? groceryData : null}
                                                placeholder="Select Products Product!!!"
                                                treeCheckable={true}
                                                allowClear={true}
                                                showArrow={true}
                                                maxTagCount={20}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                onChange={this.updateChild}
                                                style={{
                                                    width: '100%'
                                                }}
                                            />,
                                        )}
                                    </Form.Item> : null}
                                    {/* <Form.Item
                                        label="Brand"
                                    >
                                        {getFieldDecorator('brand', {
                                            rules: [{ required: true, message: 'Please Select Brand!' }],
                                        })(
                                            <Select
                                                showSearch
                                                showArrow
                                                mode="tags"
                                                style={{ backgroundColor: '#fff' }}
                                                onSelect={() => this.checkLimit('brand')}
                                                placeholder="Select Brand"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    saleType.map((v, i) => {
                                                        return <Option value={v} key={i}>{v}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item> */}
                                    <Form.Item>
                                        <SubmitButton loading={loading} disable={disable} />
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                        <QuestionModal visible={visible} handleOk={this.handleOk} handleCancel={this.handleCancel} question={this.state.question} answers={this.state.answers} key={this.state.key} openNotification={this.openNotification} />
                        {showModal ? <ShowQuestion visible={showModal} currentQuestion={currentQuestion} hideModal={this.hideModal} /> : null}
                    </section>

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const FocusGroupForm = Form.create({ name: 'normal_login' })(FocusGroup);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FocusGroupForm)