import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Upload, Select, Table, DatePicker, Avatar, notification, Popconfirm, TreeSelect, Switch } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'
import Highlighter from 'react-highlight-words';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'
import SubmitButton from '../../Components/SubmitButton'
import moment from 'moment'
import FormModal from './FormModal'
import InspectModal from './Inspect'
import { serverUrl } from '../../Config/api'

var expandData = []
var expandData1 = []
const { Option } = Select
const { TextArea } = Input;
const saleType = ["A", "B", "C"]

const title = "Error"

const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture',
};

const data = [];
for (let i = 1; i < 46; i++) {
    data.push({
        key: i,
        name: `Q&A_00${i}`,
        topic: i,
        ans1: 'h',
        consumer: i,
        address: i > 4 ? `A_0${Math.round(i / 4) > 4 ? Math.round(Math.round(i / 4) / 4) : Math.round(i / 4)}` : `A_0${i}`
    });
}

class OneToOne extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            disableUpload: false,
            StartDateValue: '',
            EndDateValue: '',
            disable: false,
            tableData: [],
            tableData1: [],
            allData: [],
            allData1: [],
            focusData: [],
            offerData: [],
            visible: false,
            groceryData: [],
            buSwitch: false,
            cuSwitch: false,
            showInspect: false,
            inspectValue: {}
        }
    }

    componentDidMount() {
        const { user } = this.props
        let { focusData, offerData, tableData, tableData1 } = this.state

        if (!user || user.role !== 'admin') {
            this.props.history.replace('/login')
        }

        axios.post(`${serverUrl}/post/get-one-pending`, { country: user.country })
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData1.push({
                        key: data[i]._id,
                        image1: data[i].image.url,
                        name1: data[i].name,
                        offerType1: data[i].Offertype,
                        sDate1: data[i].startDate,
                        eDate1: data[i].endDate,
                        consumer1: data[i].focusGroup.length,
                        action1: data[i],
                        inspect: data[i],
                        index: i
                    })
                }
                this.setState({ tableData1, allData1: data })
            })
            .catch((e) => console.log(e))

        axios.get(`${serverUrl}/admin/get/get-product/${user._id}`)
            .then((result) => {
                const { data, success } = result.data
                if (success) {

                    var groceryData = []

                    for (var i in data) {
                        var obj = {
                            title: data[i].groceryName,
                            value: i,
                            key: i,
                            children: []
                        }
                        var { excelFileObj } = data[i]
                        var count = 0
                        for (var j in excelFileObj) {
                            if (j !== "null") {
                                obj.children.push({
                                    title: j,
                                    value: `${i}-${count}`,
                                    key: `${i}-${count}`,
                                    children: []
                                })
                                var childCount = 0
                                for (var k in excelFileObj[j]) {
                                    if (k !== "null") {
                                        obj.children[count].children.push({
                                            title: k,
                                            value: `${i}-${count}-${childCount}`,
                                            key: `${i}-${count}-${childCount}`,
                                            children: []
                                        })
                                        for (var l in excelFileObj[j][k]) {
                                            obj.children[count].children[childCount].children.push({
                                                title: excelFileObj[j][k][l]['product_title'],
                                                value: `${i}-${count}-${childCount}-${l}`,
                                                key: `${i}-${count}-${childCount}-${l}`,
                                                data: excelFileObj[j][k][l]
                                            })
                                        }
                                        childCount++
                                    }
                                }
                                count++
                            }
                        }
                        groceryData.push(obj)
                    }
                    this.setState({ groceryData })
                }
            })


        axios.get(`${serverUrl}/admin/get/get-focus/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i of data) {
                    focusData.push(i.name)
                }
                focusData = focusData.filter((v, i, a) => a.indexOf(v) === i);
                this.setState({ focusData })
            })

        axios.get(`${serverUrl}/admin/get/get-offer/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i of data) {
                    offerData.push(i.name)
                }
                offerData = offerData.filter((v, i, a) => a.indexOf(v) === i);
                this.setState({ offerData })
            })
            .catch((e) => console.log(e))
        axios.get(`${serverUrl}/admin/get/get-1to1/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        image: data[i].image.url,
                        name: data[i].name,
                        offerType: data[i].Offertype,
                        sDate: data[i].startDate,
                        eDate: data[i].endDate,
                        consumer: data[i].focusGroup.length,
                        action: data[i],
                        bu: data[i].buSwitch ? 'True' : 'False',
                        cu: data[i].cuSwitch ? 'True' : 'False',
                        delete: data[i]._id,
                        index: i
                    })
                }
                this.setState({ tableData, allData: data })
            })
            .catch((e) => console.log(e))

    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    normFile = e => {
        this.setState({ disableUpload: false })
        if (e.file && e.file.type.indexOf('image')) {
            // this.openNotification(title, 'Please Upload an Image', 'close-circle', 'red')
            return
        }
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            this.setState({ disableUpload: true, fileList: e.fileList })
        }
        // return e && e.fileList;
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    expandedRowRender = () => {
        const columns = [
            {
                title: 'Focus Group Names',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'fc1',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'fc2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'fc3',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 4',
                        dataIndex: 'fc4',
                        ellipsis: true,
                    }
                ]
            }
        ]
        const data = [{
            fc1: expandData[0],
            fc2: expandData[1] ? expandData[1] : '',
            fc3: expandData[2] ? expandData[2] : '',
            fc4: expandData[3] ? expandData[3] : '',
            key: 0
        }]
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    expandedRowRender1 = () => {
        const columns = [
            {
                title: 'Focus Group Names',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'fc1',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'fc2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'fc3',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 4',
                        dataIndex: 'fc4',
                        ellipsis: true,
                    }
                ]
            }
        ]
        const data = [{
            fc1: expandData[0] ? expandData[0] : '',
            fc2: expandData[1] ? expandData[1] : '',
            fc3: expandData[2] ? expandData[2] : '',
            fc4: expandData[3] ? expandData[3] : '',
            key: 0
        }]
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    handleExpand = (row) => {
        const { allData } = this.state
        expandData = allData[row.index].focusGroup
    }

    handleExpand1 = (row) => {
        const { allData1 } = this.state
        expandData1 = allData1[row.index].focusGroup
    }

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        const { focusGroup, name, Offertype, startDate, endDate, description, productName, buSwitch, cuSwitch } = v
        const { fileList } = this.state
        v.image.name = `${v.image.public_id}.${v.image.format}`
        v.image.thumbUrl = v.image.url
        v.image.uid = v._id
        fileList.push(v.image)
        this.setState({
            fileList,
            buSwitch,
            cuSwitch
        })
        setFieldsValue({
            focusGroup,
            name,
            Offertype,
            startDate: moment(startDate),
            endDate: moment(endDate),
            description,
            image: v.image,
            productName,
            buSwitch,
            cuSwitch
        })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    handleSubmit = e => {
        const { groceryData } = this.state
        const { user, form } = this.props
        const { resetFields, validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                var formData = new FormData();
                for (var i in values) {
                    formData.append(i, values[i])
                }
                formData.set('focusGroup', JSON.stringify(values.focusGroup))
                if (values.productName !== undefined) {
                    for (var i of groceryData) {
                        if (values.productName === i.value) {
                            values.productData = i
                            break
                        }
                        else {
                            for (var j of i.children) {
                                if (values.productName === j.value) {
                                    values.productData = j
                                    break
                                }
                                else {
                                    for (var k of j.children) {
                                        if (values.productName === k.value) {
                                            values.productData = k
                                            break
                                        }
                                        else {
                                            for (var l of k.children) {
                                                if (values.productName === l.value) {
                                                    values.productData = l
                                                    break
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    formData.set('productData', JSON.stringify(values.productData))
                }
                else {
                    formData.set('productName', '')
                }
                if (values.image.file) {
                    formData.set('image', values.image.file.originFileObj)
                }
                else {
                    formData.set('image', JSON.stringify(values.image))
                }
                axios.post(`${serverUrl}/admin/post/add-1to1`, formData)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            resetFields()
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    disabledEndDate(current) {
        return current < moment(this.state.StartDateValue)
    }

    disabledStartDate(current) {
        return current > moment(this.state.EndDateValue).add(1, 'day')
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    async deleteData(v) {
        let { tableData } = this.state
        await axios.post(`${serverUrl}/admin/post/1to1-delete`, { id: v })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData = tableData.filter(i => i.key !== v)
                    this.setState({ tableData })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    approvedData(v) {
        let { tableData1 } = this.state
        axios.get(`${serverUrl}/get/approved-buone/${v._id}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    tableData1 = tableData1.filter(i => i.key !== v._id)
                    this.setState({ tableData1 })
                    this.openNotification('Success', 'Successfully Approved', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    async rejectData(v) {
        let { tableData1 } = this.state
        await axios.get(`${serverUrl}/get/delete-buone/${v}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData1 = tableData1.filter(i => i.key !== v)
                    this.setState({ tableData1 })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        let { sortedInfo, filteredInfo, loading, disable, tableData, focusData, groceryData, offerData, buSwitch, cuSwitch, tableData1, showInspect, inspectValue } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Image',
                dataIndex: 'image',
                render: (url) => <Avatar size={60} icon="user" src={url} />
            },
            {
                title: 'BU',
                dataIndex: 'bu',
                ellipsis: true
            },
            {
                title: 'CU',
                dataIndex: 'cu',
                ellipsis: true
            },
            {
                title: '1to1 Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Offer Type',
                dataIndex: 'offerType',
                sorter: (a, b) => a.offerType.length - b.offerType.length,
                sortOrder: sortedInfo.columnKey === 'offerType' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('offerType'),
            },
            {
                title: 'Start Date',
                dataIndex: 'sDate',
                ellipsis: true,
                sorter: (a, b) => a.sDate.length - b.sDate.length,
                sortOrder: sortedInfo.columnKey === 'sDate' && sortedInfo.order
            },
            {
                title: 'End Date',
                dataIndex: 'eDate',
                ellipsis: true,
                sorter: (a, b) => a.eDate.length - b.eDate.length,
                sortOrder: sortedInfo.columnKey === 'eDate' && sortedInfo.order
            },
            {
                title: 'Create',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.createCopyData(v)}>Create</Button>
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={() => this.deleteData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        const columns1 = [
            {
                title: 'Image',
                dataIndex: 'image1',
                render: (url) => <Avatar size={60} icon="user" src={url} />
            },
            {
                title: '1to1 Name',
                dataIndex: 'name1',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name1'),
            },
            {
                title: 'Offer Type',
                dataIndex: 'offerType1',
                sorter: (a, b) => a.offerType.length - b.offerType.length,
                sortOrder: sortedInfo.columnKey === 'offerType1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('offerType1'),
            },
            {
                title: 'Start Date',
                dataIndex: 'sDate1',
                ellipsis: true,
            },
            {
                title: 'End Date',
                dataIndex: 'eDate1',
                ellipsis: true,
            },
            {
                title: 'Action',
                dataIndex: 'action1',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.approvedData(v)}>Approve</Button>
            },
            {
                title: 'Inspect',
                dataIndex: 'inspect',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.setState({ showInspect: true, inspectValue: v })} type="primary">Inspect</Button>
            },
            {
                title: 'Reject',
                dataIndex: 'delete1',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure to reject this data?"
                    onConfirm={() => this.rejectData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Reject</Button>
                </Popconfirm>
            }
        ]

        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a className="nav-link dropdown-toggle nav-link-child" href="#" id="navbarDropdownChild" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Delivery
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdownChild">
                                                <Link className="dropdown-item" to="/timeslot">TimeSlot</Link>
                                                <Link className="dropdown-item" to="/region">Region</Link>
                                                <Link className="dropdown-item" to="/costs">Costs</Link>
                                            </div>
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li><li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>


                <div className="main-content-bg2">
                    <section>

                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>1 To 1 Offers</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={tableData}
                                    onChange={this.handleChange}
                                    expandedRowRender={this.expandedRowRender}
                                    onRow={(row, rowIndex) => this.handleExpand(row)}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1500, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Bu's 1 To 1 Offers</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    onChange={this.handleChange}
                                    columns={columns1}
                                    dataSource={tableData1}
                                    onRow={(row, rowIndex) => this.handleExpand1(row)}
                                    expandedRowRender={this.expandedRowRender1}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1400, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    onSubmit={this.handleSubmit} className="login-form"
                                    hideRequiredMark={true}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >1 To 1 Offers Settings</h1>
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={3}
                                                placeholder="1 To 1 offer name"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="BU"
                                    >
                                        {getFieldDecorator('buSwitch', {
                                            initialValue: false
                                        })(
                                            <Switch checked={buSwitch} onChange={e => this.setState({ buSwitch: e })} />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="CU"
                                    >
                                        {getFieldDecorator('cuSwitch', {
                                            initialValue: false
                                        })(
                                            <Switch checked={cuSwitch} onChange={e => this.setState({ cuSwitch: e })} />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Focusgroup"
                                    >
                                        {getFieldDecorator('focusGroup', {
                                            rules: [{ required: true, message: 'Please Select Focusgroup Name!' }],
                                        })(
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                showArrow
                                                onSelect={() => this.checkLimit('focusGroup')}
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Focusgroup Name"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    focusData.map((v, i) => {
                                                        return <Option value={v} key={i}>{v}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Product Name"
                                    >
                                        {getFieldDecorator('productName', {
                                            rules: [{ required: false, message: 'Please Select Product Name!' }],
                                        })(
                                            <TreeSelect
                                                treeData={groceryData.length ? groceryData : null}
                                                placeholder="Select Products!"
                                                allowClear={true}
                                                showArrow={true}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                style={{
                                                    width: '100%'
                                                }}
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Offer Type"
                                    >
                                        {getFieldDecorator('Offertype', {
                                            rules: [{ required: true, message: 'Please Select Offer Type!' }],
                                        })(
                                            <Select
                                                showSearch
                                                showArrow
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Offer Type"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    offerData.map((v, i) => {
                                                        return <Option value={v} key={i}>{v}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Upload Image"
                                    >
                                        {getFieldDecorator('image', {
                                            rules: [{ required: true, message: 'Please upload Image!' }],
                                        })(
                                            <Upload {...props}
                                                onChange={this.normFile}
                                                fileList={this.state.fileList}
                                                onRemove={() => this.setState({ fileList: [] })}
                                                accept="image/*">
                                                <Button disabled={this.state.fileList.length ? true : false} >
                                                    <Icon type="upload" /> Upload
                                                    </Button>
                                            </Upload>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Start Date"
                                    >
                                        {getFieldDecorator('startDate', {
                                            rules: [{ required: true, message: 'Please input Sold Price!' }],
                                        })(
                                            <DatePicker
                                                showTime={false}
                                                onChange={(e) => this.setState({ StartDateValue: e })}
                                                format="YYYY-MM-DD"
                                                disabledDate={this.disabledStartDate.bind(this)}
                                                placeholder="Start Date"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="End Date"
                                    >
                                        {getFieldDecorator('endDate', {
                                            rules: [{ required: true, message: 'Please Select Sale Type!' }],
                                        })(
                                            <DatePicker
                                                showTime={false}
                                                onChange={(e) => this.setState({ EndDateValue: e })}
                                                format="YYYY-MM-DD"
                                                placeholder="End Date"
                                                disabledDate={this.disabledEndDate.bind(this)}
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Description"
                                    >
                                        {getFieldDecorator('description', {
                                            rules: [{ required: true, message: 'Please input description!' }],
                                        })(
                                            <TextArea rows={4} placeholder="Input Description Here!!!" />,
                                        )}
                                    </Form.Item>

                                    <Form.Item>
                                        <SubmitButton loading={loading} disable={disable} />
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                        {/* <FormModal visible={visible} /> */}
                        {showInspect ? <InspectModal visible={showInspect} inspectValue={inspectValue} disableModal={() => this.setState({ showInspect: false, inspectValue: {} })} handleOk={() => this.setState({ showInspect: false }, () => this.approvedData(inspectValue))} /> : null}
                        <Footer {...this.props} />
                    </section>
                </div>
            </div>
        )
    }
}

const OneToOneForm = Form.create({ name: 'normal_login' })(OneToOne);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(OneToOneForm)