import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import logo from '../assets/pantrylogo.png'
import { Icon, List, Button, notification, Modal, Tabs, Popconfirm, message, Input, Badge } from 'antd';
import axios from 'axios'
import moment from 'moment'

const { TabPane } = Tabs
const title = "Error"

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}


class ShoppingList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            visible: false,
            questionsData: [],
            productsData: {},
            isData: false,
            loadData: true,
            shoppinData: [],
            allTopics: [],
            orderOfData: [],
            productData: [],
            currentProduct: [],
            showAdd: false,
            topicsData: [],
            showSearch: false,
            selectedData: [],
            currentTopic: [],
            currentData: [],
            groceryData: [],
            quantity: {},
            loadingData: false,
            totalPrice: {},
            isAds: true,
            buyLoading: false,
            saveLoading: false
        }
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    }

    async componentDidMount() {
        let { groceryData } = this.state
        const { user, inspectValue } = this.props
        var obj = {
            title: inspectValue.groceryName,
            value: 0,
            key: 0,
            imageLink: inspectValue.imageLink,
            buId: inspectValue.buId,
            children: []
        }
        var { excelFileObj } = inspectValue
        var count = 0
        var i = 0
        for (var j in excelFileObj) {
            if (j !== "null") {
                obj.children.push({
                    title: j,
                    value: `${i}-${count}`,
                    key: `${i}-${count}`,
                    children: []
                })
                var childCount = 0
                for (var k in excelFileObj[j]) {
                    if (k !== "null") {
                        obj.children[count].children.push({
                            title: k,
                            value: `${i}-${count}-${childCount}`,
                            key: `${i}-${count}-${childCount}`,
                            children: []
                        })
                        for (var l in excelFileObj[j][k]) {
                            obj.children[count].children[childCount].children.push({
                                title: excelFileObj[j][k][l]['product_title'],
                                value: `${i}-${count}-${childCount}-${l}`,
                                key: `${i}-${count}-${childCount}-${l}`,
                                data: excelFileObj[j][k][l]
                            })
                        }
                        childCount++
                    }
                }
                count++
            }
        }
        groceryData.push(obj)
        this.setState({ groceryData })
    }

    render() {
        let { groceryData } = this.state;

        const { disableModal, user, visible, handleOk } = this.props

        return (
            <Modal
                visible={visible}
                title="BU Products List"
                onCancel={disableModal}
                className="set-modal"
                footer={[
                    <Button key="back" onClick={disableModal}>
                        Return
                    </Button>,
                ]}
            >
                <div style={{ minHeight: 500, maxHeight: 800, overflowY: 'scroll' }}>
                    {Object.entries(groceryData).map((v, i) => {
                        return v[1].children.map((y, j) => {
                            return y.children.map((z, k) => {
                                return <div key={`${i}-${j}-${k}`}>
                                    <div className="list-head">
                                        <p className="set-size" style={{ flex: 1, borderRight: '1px solid black' }}>{z.title}</p>
                                        <p className="set-size" style={{ flex: 1 }}>{v[1].title}</p>
                                    </div>
                                    <div>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={z.children}
                                            renderItem={(item, key) => (
                                                <List.Item
                                                    className="list-item"
                                                >
                                                    <div style={{ display: 'flex', width: '100%', transition: '0.3s', height: '100px' }} id={`${i}-${j}-${k}-${key}`} className="list-data">
                                                        <List.Item.Meta
                                                            style={{ padding: 20 }}
                                                            description={
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flex: 1
                                                                }}>
                                                                    <div className="img-div">
                                                                        <img src={item.data['image_link']} style={{ marginTop: -50, height: 140 }} />
                                                                    </div>
                                                                    <div className="mobile-ver">
                                                                        <div className="mid-div" style={{ flex: 2 }}>
                                                                            <p className="set-size" style={{ flex: 1 }}>{item.data['product_title']}</p>
                                                                        </div>
                                                                        <div className="last-div" style={{ flexDirection: 'column' }}>
                                                                            <p className="set-size" style={{ flex: 1 }}>€ {item.data['current_price']}</p>
                                                                            <p className="set-size">{item.data['unit_sold']}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        />
                                                    </div>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </div>
                            })
                        })
                    })}
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        ads: state.authReducer.ads,
    }
}




export default connect(mapStateToProps, null)(ShoppingList)