import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { Home, Login, Register, LoginContact, Forget, Account, OneToOne, Ad, Qanda, FocusGroup, Subscription, Insight, Invoice, Privacy, Contact, Admin, FAQ, About, Term, Grocery, UserGroup, Topic, OfferType, ProductGroup, ProductType, Product, TimeSlot, Region, Costs } from '../Screens'


function PrivateRoute({ component: Component, isLoggedIn, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => isLoggedIn === true ? (
                <Component {...props} />
            ) : (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
            }
        />
    );
}


class Routes extends Component {

    state = {
        user: null,
    }

    UNSAFE_componentDidMount() {
        const { user } = this.props
        if (user) {
            this.setState({ user: user })
        }
    }


    render() {


        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/register" exact component={Register} />
                    <Route path="/forgot" exact component={Forget} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/login-contact" exact component={LoginContact} />
                    <Route path="/account" exact component={Account} />
                    <Route path="/1to1" exact component={OneToOne} />
                    <Route path="/ad" exact component={Ad} />
                    <Route path="/qanda" exact component={Qanda} />
                    <Route path="/focusgroup" exact component={FocusGroup} />
                    <Route path="/subscription" exact component={Subscription} />
                    <Route path="/insight" exact component={Insight} />
                    <Route path="/invoice" exact component={Invoice} />
                    <Route path="/privacy" exact component={Privacy} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/admin" exact component={Admin} />
                    <Route path="/faq" exact component={FAQ} />
                    <Route path="/about" exact component={About} />
                    <Route path="/terms" exact component={Term} />
                    <Route path="/grocery" exact component={Grocery} />
                    <Route path="/user-group" exact component={UserGroup} />
                    <Route path="/topic" exact component={Topic} />
                    <Route path="/offer-type" exact component={OfferType} />
                    <Route path="/product-group" exact component={ProductGroup} />
                    <Route path="/product-type" exact component={ProductType} />
                    <Route path="/product" exact component={Product} />
                    <Route path="/timeslot" exact component={TimeSlot} />
                    <Route path="/region" exact component={Region} />
                    <Route path="/costs" exact component={Costs} />
                </Switch>
            </Router>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

export default connect(mapStateToProps, null)(Routes)

