import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Upload, Select, message, DatePicker } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'
import Footer from '../../Components/Footer'
import logo from '../../assets/pantrylogo.png'

const { TextArea } = Input


class Contact extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a className="nav-link dropdown-toggle nav-link-child" href="#" id="navbarDropdownChild" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Delivery
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdownChild">
                                                <Link className="dropdown-item" to="/timeslot">TimeSlot</Link>
                                                <Link className="dropdown-item" to="/region">Region</Link>
                                                <Link className="dropdown-item" to="/costs">Costs</Link>
                                            </div>
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li><li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg">
                    <div id="contact" />
                    <section className="contact">
                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >Contact</h1>
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={3}
                                                placeholder="Name"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                    >
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                    >
                                        {getFieldDecorator('description', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <TextArea
                                                rows={3}
                                                placeholder="Description"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button htmlType="submit" className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                VERSTUUR
                                        </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>

                    <div id="video" />
                    <section className="video">
                        <div className="container">
                            <h2>How to video's</h2>
                            <div className="row px-md-5 px-0 ">
                                <div className="col-sm-6 py-sm-5 py-3">
                                    <div className="video-thumbnail teacher">
                                        <img src="images/contact-video/teacher-video.jpg.png" alt="teacher-video" className="w-100" />
                                        <div className="icon">
                                            <button type="button" className="btn" data-toggle="modal" data-target="#teacher-video-modal">
                                                <i className="far fa-play-circle" />
                                            </button>
                                        </div>
                                    </div>
                                    <h5>Testally for Teachers</h5>
                                    <div className="modal fade" id="teacher-video-modal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title" id="myModalLabel">Testally for Teachers</h4>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                </div>
                                                <div className="modal-body">
                                                    <video id="teacher-video" width="100%" controls>
                                                        <source src="Video/TestAllyteacherfreeeng.mp4" type="video/mp4" />
                                                        Your browser does not support HTML5 video.
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 py-sm-5">
                                    <div className="video-thumbnail teacher">
                                        <img src="images/contact-video/student-video.png" alt="student-video" className="w-100" />
                                        <div className="icon">
                                            <button type="button" className="btn" data-toggle="modal" data-target="#student-video-modal">
                                                <i className="far fa-play-circle" />
                                            </button>
                                        </div>
                                    </div>
                                    <h5>Testally for Learning companies</h5>
                                    <div className="modal fade" id="student-video-modal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title" id="myModalLabel">Testally for Learning companies</h4>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                </div>
                                                <div className="modal-body">
                                                    <video id="student-video" width="100%" controls>
                                                        <source src="Video/TestAllycompeng.mp4" type="video/mp4" />
                                                        Your browser does not support HTML5 video.
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer {...this.props} />
            </div>
        )
    }
}

const ContactForm = Form.create({ name: 'normal_login' })(Contact);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)