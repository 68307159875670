import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Form, Icon, Input, Button, Upload, Select, notification, Table, Popconfirm, message, Switch } from 'antd';
import axios from 'axios'
import moment from 'moment'
import { loginUser } from '../../Redux/actions/authActions'
import Highlighter from 'react-highlight-words';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import InspectView from '../../Components/InspectView'
import { serverUrl } from '../../Config/api'



const { Option } = Select
const { TextArea } = Input;

const title = "Error"

const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
};

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
    if (!isJpgOrPng) {
        message.error('You can only upload csv/xlsx file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
        message.error('File must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            disableUpload: false,
            StartDateValue: '',
            EndDateValue: '',
            disable: false,
            tableData: [],
            tableData1: [],
            allData: [],
            allData1: [],
            groceryData: [],
            visible: false,
            isDelete: false,
            showInspect: false,
            inspectValue: {}
        }
    }

    componentDidMount() {
        const { user } = this.props
        const { tableData, tableData1 } = this.state
        axios.get(`${serverUrl}/admin/user/getall-user/${user._id}`)
            .then((result) => {
                const { data } = result.data
                this.setState({ groceryData: data })
            })

        axios.get(`${serverUrl}/admin/get/get-product/${user._id}`)
            .then((result) => {
                const { data } = result.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        name: data[i].name,
                        date: data[i].uploadDate,
                        grocery: data[i].groceryName,
                        topic: data[i].topic.name,
                        product: data[i].excelFileData,
                        imageLink: data[i].imageLink ? 'True' : 'False',
                        delete: data[i]._id,
                        index: i
                    })
                }
                this.setState({ tableData, allData: data })
            })

        axios.post(`${serverUrl}/admin/get/get-product-country`, {
            country: user.country
        })
            .then((result) => {
                const { data } = result.data
                for (var i in data) {
                    tableData1.push({
                        key: data[i]._id,
                        name1: data[i].name,
                        date1: data[i].uploadDate,
                        grocery1: data[i].groceryName,
                        topic1: data[i].topic.name,
                        product1: data[i].excelFileData,
                        imageLink1: data[i].imageLink ? 'True' : 'False',
                        inspect: data[i],
                        approve: data[i],
                        index: i
                    })
                }
                this.setState({ tableData1, allData1: data })
            })
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    normFile = e => {
        this.setState({ disableUpload: false })
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            this.setState({ disableUpload: true, fileList: e.fileList })
        }
        return e && e.fileList;
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        const { focusGroup, name, Offertype, startDate, endDate, description } = v
        const { fileList } = this.state
        v.image.name = `${v.image.public_id}.${v.image.format}`
        v.image.thumbUrl = v.image.url
        v.image.uid = v._id
        fileList.push(v.image)
        this.setState({
            fileList
        })
        setFieldsValue({
            focusGroup,
            name,
            Offertype,
            startDate: moment(startDate),
            endDate: moment(endDate),
            description,
            image: v.image
        })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    handleSubmit = e => {
        let { groceryData } = this.state
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                var obj = groceryData[values.groceryName]
                values.buId = obj._id
                values.topic = JSON.stringify(obj.topic)
                values.groceryName = `${obj.userName}, ${obj.streetAddress}`
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = JSON.stringify(user.country)
                values.uploadDate = moment().format('LL')
                values.approved = false
                var formData = new FormData();
                for (var i in values) {
                    formData.append(i, values[i])
                }
                if (values.excelFile.file) {
                    formData.set('excelFile', values.excelFile.file.originFileObj)
                }
                else {
                    formData.set('excelFile', JSON.stringify(values.excelFile))
                }
                axios.post(`${serverUrl}/admin/post/add-product`, formData)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    disabledEndDate(current) {
        return current < moment(this.state.StartDateValue).add(1, 'day')
    }

    disabledStartDate(current) {
        return current > moment(this.state.EndDateValue)
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    async deleteData(v) {
        this.setState({ isDelete: true })
        let { tableData } = this.state
        await axios.post(`${serverUrl}/admin/post/product-delete`, { _id: v })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData = tableData.filter(i => i.key !== v)
                    this.setState({ tableData, isDelete: false })
                    setTimeout(() => {
                        window.location.reload()
                    }, 300);
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.setState({ isDelete: false })
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.setState({ isDelete: false })
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    async approveData(v) {
        const { user } = this.props
        delete v._id
        v.buId = v.userId
        v.userId = user._id
        v.approved = true
        v.country = user.country
        axios.post(`${serverUrl}/admin/post/approve-product`, v)
            .then((result) => {
                if (result.data.success) {
                    this.openNotification('Success', result.data.message, 'check')
                    window.location.reload()
                }
                else {
                    this.openNotification(title, result.data.message, 'close-circle', 'red')
                    this.setState({ disable: false, loading: false })
                }
            })
            .catch((err) => {
                this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                this.setState({ disable: false, loading: false })
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        let { sortedInfo, filteredInfo, loading, disable, tableData, groceryData, tableData1, showInspect, inspectValue } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Grocery Name',
                dataIndex: 'grocery',
                sorter: (a, b) => a.grocery.length - b.grocery.length,
                sortOrder: sortedInfo.columnKey === 'grocery' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('grocery')
            },
            {
                title: 'Topic',
                dataIndex: 'topic',
                sorter: (a, b) => a.topic.length - b.topic.length,
                sortOrder: sortedInfo.columnKey === 'topic' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('topic')
            },
            {
                title: 'Upload Date',
                dataIndex: 'date',
                ellipsis: true,
                ...this.getColumnSearchProps('date')
            },
            {
                title: 'Image Link',
                dataIndex: 'imageLink',
                ellipsis: true
            },
            {
                title: 'Total Products',
                dataIndex: 'product',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'product' && sortedInfo.order,
                ellipsis: true
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={() => this.deleteData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        const columns1 = [
            {
                title: 'Name',
                dataIndex: 'name1',
                sorter: (a, b) => a.name1.length - b.name1.length,
                sortOrder: sortedInfo.columnKey === 'name1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name1'),
            },
            {
                title: 'Grocery Name',
                dataIndex: 'grocery1',
                sorter: (a, b) => a.grocery1.length - b.grocery1.length,
                sortOrder: sortedInfo.columnKey === 'grocery1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('grocery1')
            },
            {
                title: 'Topic',
                dataIndex: 'topic1',
                sorter: (a, b) => a.topic1.length - b.topic1.length,
                sortOrder: sortedInfo.columnKey === 'topic1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('topic1')
            },
            {
                title: 'Upload Date',
                dataIndex: 'date1',
                ellipsis: true,
                ...this.getColumnSearchProps('date1')
            },
            {
                title: 'Image Link',
                dataIndex: 'imageLink1',
                ellipsis: true
            },
            {
                title: 'Total Products1',
                dataIndex: 'product1',
                sorter: (a, b) => a.product1 - b.product1,
                sortOrder: sortedInfo.columnKey === 'product1' && sortedInfo.order,
                ellipsis: true
            },
            {
                title: 'Inspect',
                dataIndex: 'inspect',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.setState({ showInspect: true, inspectValue: v })} type="primary">Inspect</Button>
            },
            {
                title: 'Approve',
                dataIndex: 'approve',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure to approve this data?"
                    onConfirm={() => this.approveData(v)}
                    okText="Yes"
                    disabled={v.approved}
                    cancelText="No"
                >
                    <Button type="primary" disabled={v.approved}
                    >Approve</Button>
                </Popconfirm>
            }
        ];

        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item"><Link className="nav-link" to="/">Home</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <a className="nav-link dropdown-toggle nav-link-child" href="#" id="navbarDropdownChild" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Delivery
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdownChild">
                                                <Link className="dropdown-item" to="/timeslot">TimeSlot</Link>
                                                <Link className="dropdown-item" to="/region">Region</Link>
                                                <Link className="dropdown-item" to="/costs">Costs</Link>
                                            </div>
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li><li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>


                <div className="main-content-bg2">
                    <section>
                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Products</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={tableData}
                                    onChange={this.handleChange}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1400, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>BU’s Products</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns1}
                                    dataSource={tableData1}
                                    onChange={this.handleChange}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1400, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    onSubmit={this.handleSubmit}
                                    className="login-form"
                                    hideRequiredMark={true}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >PRODUCTS SETTINGS</h1>
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please Input Name!' }],
                                        })(
                                            <Input placeholder="Name" minLength={3} />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Grocery"
                                    >
                                        {getFieldDecorator('groceryName', {
                                            rules: [{ required: true, message: 'Please Select Grocery!' }],
                                        })(
                                            <Select
                                                showSearch
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Offer Grocery"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    groceryData.map((v, i) => {
                                                        return <Option value={i} key={i}>{`${v.userName}, ${v.streetAddress}`}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Image Link"
                                    >
                                        {getFieldDecorator('imageLink', {
                                            initialValue: false,
                                            rules: [{ required: true }],
                                        })(
                                            <Switch />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Upload Excel file"
                                    >
                                        {getFieldDecorator('excelFile', {
                                            rules: [{ required: true, message: 'Please upload Excel!' }],
                                        })(
                                            <Upload {...props}
                                                onChange={this.normFile}
                                                fileList={this.state.fileList}
                                                beforeUpload={beforeUpload}
                                                onRemove={() => this.setState({ fileList: [] })}
                                                accept=".xlsx">
                                                <Button disabled={this.state.fileList.length ? true : false} >
                                                    <Icon type="upload" /> Upload
                                            </Button>
                                            </Upload>,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <SubmitButton loading={loading} disable={disable} />
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                        <Footer {...this.props} />
                    </section>
                </div>
                {showInspect ? <InspectView visible={showInspect} inspectValue={inspectValue} disableModal={() => this.setState({ showInspect: false })} /> : null}
            </div>
        )
    }
}

const ProductForm = Form.create({ name: 'normal_login' })(Product);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ProductForm)